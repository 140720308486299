<template>
  <el-main>
    <router-link to="/service/addService">
      <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus">添加服务</el-button>
    </router-link>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="服务名称：">
        <el-input v-model="searchForm.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="服务分类：">
        <el-cascader
          v-model="searchForm.classify_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="serviceClassify"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select v-model="searchForm.status" size="small">
          <el-option label="全部" :value="''"></el-option>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="18px">
        <el-button type="primary" @click="search" size="small">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="75px">
        <template #header>
          <el-checkbox v-model="allchecked" @change="selectToatl">全选</el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checked" @change="selectChange"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="120" align="center">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input type="number" :min="0" size="small" v-model="scope.row.sort" @change="editSort(scope.row, 1)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="picture" label="服务主图" align="center">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.pictures[0] ? scope.row.pictures[0] : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="classify_id" label="服务分类" align="center"></el-table-column>
      <el-table-column prop="name" label="服务名称" width="200" align="center"></el-table-column>
      <el-table-column prop="ratio" label="推广奖励比例" width="200" align="center">
        <template v-slot="{ row }">{{ row.ratio }}%</template>
      </el-table-column>
      <el-table-column prop="status" label="服务状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '启用' : '停用' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="btn">
            <el-button @click="$router.push({ path: '/service/serviceInfo', query: { id: scope.row.id } })" type="text" size="small">编辑</el-button>
            <el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
      <div slot="batch">
        <el-checkbox v-model="allchecked" @change="selectToatl" style="margin-right: 20px">全选</el-checkbox>
        <el-select placeholder="批量操作" size="mini" v-model="batchActionId" style="margin-right: 20px">
          <el-option label="删除" :value="1"></el-option>
        </el-select>
        <el-button size="mini" @click="handleBatchAction">确定</el-button>
      </div>
    </Paging>
  </el-main>
</template>
<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        classify_id: [],
        name: '',
        status: '',
      },
      allchecked: !1,
      batchActionId: '',
      list: [],
      serviceClassify: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
    this.$axios
      .post(this.$api.samecity.service.classifyList, {
        is_show: this.is_show,
        name: this.name,
      })
      .then(res => {
        if (res.code == 0) {
          this.serviceClassify = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    //全选
    selectToatl(val) {
      if (val) {
        for (let i in this.list) this.list[i].checked = !0;
      } else {
        for (let i in this.list) this.list[i].checked = !1;
      }
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        classify_id: [],
        name: '',
        status: '',
      };
      this.getList();
    },
    //批量操作
    handleBatchAction() {
      let list = this.list;
      if (this.batchActionId == 1) {
        //批量删除服务
        let id = [];
        for (let i in list) {
          if (list[i].checked) id.push(list[i].id);
        }
        if (!id.length) return this.$message.warning('请选择要删除的服务');
        this.$confirm('请确认是否批量删除所选服务', '批量删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.samecity.service.delService, {
              id: id,
            })
            .then(res => {
              if (res.code == 0) {
                this.getList();
                this.$message.success('删除成功');
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      } else {
        this.$message.warning('请选择批量操作类型');
      }
    },
    getList() {
      let searchForm = Object.assign({}, this.searchForm);
      if (searchForm.classify_id.length) {
        searchForm.classify_id.length > 1 ? (searchForm.classify_id = searchForm.classify_id[1]) : (searchForm.classify_id = searchForm.classify_id[0]);
      }
      this.$axios.post(this.$api.samecity.service.serviceList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.checked = !1;
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(id) {
      this.$confirm('确认删除此服务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.service.delService, {
            id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    editSort(row) {
      this.$axios
        .post(this.$api.samecity.service.editSort, {
          sort: row.sort,
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>